/** @jsx jsx */
import { css, jsx } from "@emotion/react";
// import * as React from "react";

const Footer = ({ color = "black", islab = false }): JSX.Element => {
  const flexStyle: React.CSSProperties = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  };

  const itemStyle: React.CSSProperties = {
    lineHeight: "26px",
  };

  const linkCss = css`
    color: ${color};
    /* text-decoration-thickness: 2px; */
    text-decoration-line: none;
    line-height: 26px;
    &:hover {
      text-decoration-line: underline;
    }
  `;

  const instaLink = islab
    ? "https://www.instagram.com/formlabproject/"
    : "https://instagram.com/andform";

  const fbLink = islab
    ? "https://www.facebook.com/formlabproject"
    : "https://www.facebook.com/andform.jp";

  const twLink = islab
    ? "https://twitter.com/andform_jp"
    : "https://twitter.com/andform_jp";

  return (
    <footer
      css={css`
        margin: 20px;
        @media (min-width: 768px) {
          margin-top: 66px;
        }
        @media (max-width: 767px) {
          margin-top: 70px;
        }
      `}
      style={{
        color,
        borderTop: "1px solid " + color,
        paddingTop: "10px",
      }}
    >
      <div
        css={css`
          display: flex;
          @media (min-width: 768px) {
            flex-direction: row;
            gap: 10px;
          }
          @media (max-width: 767px) {
            flex-direction: column;
          }
        `}
      >
        <div style={{ width: "50%" }}>
          <span>&amp;Form</span>
        </div>
        <div
          css={css`
            display: flex;
            @media (min-width: 1300px) {
              flex-direction: row;
              margin-bottom: 56px;
            }
            @media (max-width: 1299px) {
              flex-direction: column;
            }
            @media (min-width: 768px) {
              width: 50%;
              margin-top: 0;
            }
            @media (max-width: 767px) {
              width: 100%;
              margin-top: 1rem;
            }
            gap: 1rem;
          `}
        >
          <div style={flexStyle}>
            <a
              href="https://goo.gl/maps/rgwxHJoMzZxNxuu59"
              target="_blank"
              css={linkCss}
            >
              {process.env.GATSBY_LANG === "JP" && (
                <span>東京都渋谷区神宮前1-10-7-103</span>
              )}
              {process.env.GATSBY_LANG === "EN" && (
                <span>1-10-7-103, Jingumae, Shibuya, Tokyo</span>
              )}
            </a>
            <span style={itemStyle}>
              T 03 6427 4950&nbsp;&nbsp;F 03 6427 4951
            </span>
            <a href="mailto:hello@andform.jp" css={linkCss}>
              hello@andform.jp
            </a>
          </div>
          <div style={flexStyle}>
            <a href={instaLink} target="_blank" css={linkCss}>
              Instagram
            </a>
            <a href={fbLink} target="_blank" css={linkCss}>
              Facebook
            </a>
            <a href={twLink} target="_blank" css={linkCss}>
              Twitter
            </a>
            <a
              href="https://www.linkedin.com/company/andform/"
              target="_blank"
              css={linkCss}
            >
              LinkedIn
            </a>
            <a
              href="https://e8f6403e.sibforms.com/serve/MUIFALLu43K6c4Nf_WWJ6dEvVSQsTlUvCQ6rLyuGE9aGy7pdR9fU_-8DEcUFJoH8nnVOhjVw7VlrE_ba9JoWzf_FzR-t_w3qnQS_QSK8S4-Al3JF2YlXYXqnv_Ju2Ai7Cl0Cfc9DGYs3H1QkzmTp9Cb-29lVCxVHXH-ti4bHrUPQn-z7lq4USruGflmpn4nLBnMO-h5Ox9ZSpRtY"
              target="_blank"
              css={linkCss}
            >
              Newsletter
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
