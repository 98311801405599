/** @jsx jsx */
import { css, jsx } from "@emotion/react";

type DataProps = {
  fontSize?: string;
  fontWeight?: number;
  color?: string;
};

/** TOOD: 後で調べる
https://qiita.com/xrxoxcxox/items/4e337b96fc9017b3771c
 */

/** JP/EN */
const LanguageSwitch = ({
  fontSize = "1rem",
  fontWeight = 700,
  color = "black",
}: DataProps): JSX.Element => {
  const link = (label: string, link: string | undefined) => {
    return (
      <span>
        <a
          href={link ?? ""}
          css={css`
            color: ${color};
            font-weight: ${fontWeight};
            text-decoration-thickness: 2px;
            text-decoration-line: ${label === process.env.GATSBY_LANG
              ? "underline"
              : "none"};
            transition-property: all;
            transition-duration: 1.5s;
            &:hover {
              text-decoration-line: underline;
            }
          `}
        >
          {label}
        </a>
      </span>
    );
  };

  return (
    <div
      style={{
        margin: 0,
        padding: 0,
        display: "flex",
        gap: "4px",
        fontSize,
        color,
        transitionProperty: "all",
        transitionDuration: "1.5s",
      }}
    >
      {process.env.GATSBY_LANG === "JP" && (
        <span
          css={css`
            color: ${color};
            font-weight: ${fontWeight};
            text-decoration-thickness: 2px;
            text-decoration: underline
          `}
        >JP</span>
      )}
      {process.env.GATSBY_LANG === "EN" && (link("JP", process.env.GATSBY_JP_TOP + location.pathname))}
      <span>/</span>
      {process.env.GATSBY_LANG === "JP" && (link("EN", process.env.GATSBY_EN_TOP + location.pathname))}
      {process.env.GATSBY_LANG === "EN" && (
        <span
          css={css`
            color: ${color};
            font-weight: ${fontWeight};
            text-decoration-thickness: 2px;
            text-decoration: underline
          `}
        >EN</span>
      )}
    </div>
  );
};

export default LanguageSwitch;
